interface Params {
    chatikOrigin: string;
    chatId?: number;
    hhtmFromLabel?: string;
}

const buildChatikUrl = ({ chatikOrigin, chatId, hhtmFromLabel }: Params): string => {
    const url = new URL(chatId ? `${chatikOrigin}/chat/${chatId}` : chatikOrigin);
    hhtmFromLabel && url.searchParams.append('hhtmFromLabel', hhtmFromLabel);

    return url.toString();
};

export default buildChatikUrl;
